import classnames from 'classnames/bind'
import React, { ReactNode, useState, DOMAttributes } from 'react'

import ConfiguratorColorOption from '~/components/ConfiguratorManager/Configurator/ConfiguratorColorOption'
import Input, { ErrorComponent, InputProps } from '~/components/Form/Input'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CheckboxProps extends InputProps {
  className?: string
  small?: boolean
  withBackground?: boolean
  label?: ReactNode | ReactNode[]
  backgroundColor?: string
}

function Checkbox({
  className,
  label,
  small,
  withBackground,
  disabled,
  backgroundColor,
  ...inputProps
}: CheckboxProps) {
  const labelTextStyle = useStyle({ textPreset: 'text-12-14' })
  const [error, setError] = useState(null)

  return (
    <label
      className={cx(css.Checkbox, className, {
        small,
        withBackground,
      })}>
      <span className={css.checkboxContainer}>
        <Input
          type="checkbox"
          {...inputProps}
          className={cx(css.input, { withCustomBackground: backgroundColor })}
          displayErrors={false}
          onError={(err) => setError(err)}>
          {({ withError }) => (
            <span
              className={cx(css.checkmark, { withError, disabled })}
              {...(backgroundColor && {
                style: {
                  background: backgroundColor,
                },
              })}
            />
          )}
        </Input>
      </span>
      <div className={css.label}>
        <div className={cx(labelTextStyle)}>{label}</div>
        <ErrorComponent error={error} />
      </div>
    </label>
  )
}

Checkbox.defaultProps = {
  small: false,
}

export default Checkbox
