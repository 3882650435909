import classnames from 'classnames/bind'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import Password from '~/components/Account/Forms/Password'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export default function Passwords() {
  const { errors, watch } = useFormContext() || {}
  const t = useTranslate()
  const textStyle = useStyle({ textPreset: 'text-11', color: 'gray-47' })

  const validate = () => watch('password') === watch('passwordConfirmation')

  const passwordConfirmationError = errors?.['passwordConfirmation']

  return (
    <div className={cx(css.Passwords)}>
      <Password
        name="password"
        autoComplete="new-password"
        placeholder={t('form.password')}
        minLength={8}
        withError={passwordConfirmationError}
      />

      {!passwordConfirmationError && (
        <label className={cx(textStyle, css.label)}>
          {t('form.password.restrictions.label')}
        </label>
      )}

      <Password
        name="passwordConfirmation"
        placeholder={t('form.confirm_password')}
        minLength={8}
        withError={passwordConfirmationError}
        validate={{ password_not_match: validate }}
      />
    </div>
  )
}
