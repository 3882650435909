import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { TRACKING_EVENTS } from '~/lib/constants'
import { register, getCustomer } from '~/lib/handle-api'
import { setAccessToken } from '~/lib/token'

import { useTracking } from '~/providers/TrackingProvider'

import useGetWishlistQueryKey from '~/hooks/account/useGetWishlistQueryKey'
import useOnMutationError from '~/hooks/account/useOnMutationError'
import useAlerts from '~/hooks/useAlerts'
import useCurrency from '~/hooks/useCurrency'
import usePrismicLocale from '~/hooks/usePrismicLocale'

import getQueryPayload from '~/utils/get-query-payload'

export default function useShopifyRegisterMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const onMutationError = useOnMutationError(setError)
  const queryClient = useQueryClient()
  const currency = useCurrency()
  const triggerAlert = useAlerts()
  const lang = usePrismicLocale()
  const tracking = useTracking()

  return useMutation<any, unknown, any, unknown>(
    async ({
      email,
      password,
      firstName,
      lastName,
      customer_birthday,
      customer_gender,
      acceptsMarketing,
    }) => {
      const payload = getQueryPayload({
        locale: lang,
        data: {
          email,
          password,
          firstName,
          lastName,
          acceptsMarketing,
        },
        metafields: {
          values: {
            customer_gender,
            customer_birthday,
          },
        },
      })

      return await register({
        store: currency,
        payload,
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //TODO: standardize error response
        const error = data?.errors?.[0]

        if (error) {
          return onMutationError(error, rest, mutationOptions?.onError)
        }

        // If a key message exists then an email will be sent to enable the account
        // (-> the customer registered to the newsletter before creating an account)
        if (data?.registeredCustomer?.message) {
          return triggerAlert('JEM_EMAIL_SENT')
        }
        const profile = await getCustomer({
          store: currency,
          accessToken: data?.registeredCustomer,
        })
        tracking.emit(TRACKING_EVENTS.SIGN_UP, { id: profile?.id ?? null })
        setAccessToken(data?.registeredCustomer, currency)
        queryClient.invalidateQueries(wishlistQueryKeys)
        mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
