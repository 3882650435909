import { useRouter } from 'next/router'
import { SingleRouteParams } from '~/lib/constants'
import linkResolver from '~/lib/link-resolver'

import useLocale from '~/hooks/useLocale'

function useSingleRoutesRedirect(): (object: SingleRouteParams) => void {
  const router = useRouter()
  const lang = useLocale()

  const redirect = (object: SingleRouteParams) => {
    router.push(
      linkResolver({
        lang,
        type: object.type,
        uid: null,
      }),
    )
  }

  return redirect
}

export default useSingleRoutesRedirect
