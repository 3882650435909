import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import ImageWithPlaceHolder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import Ratio from '~/components/Ratio'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LayoutUnLoggedProps {
  className?: string
  backgroundImage?: ImageProps
  children?: ReactNode | ReactNode[]
}

const DEFAULT_ALT = 'Account image'

function LayoutUnLogged({
  className,
  backgroundImage,
  children,
}: LayoutUnLoggedProps) {
  const grid = useStyle({ grid: 'account-grid' })

  return (
    <div className={cx(css.LayoutUnLogged, className)}>
      <div className={css.imageContainer}>
        {backgroundImage && (
          <Ratio ratio={1440 / 360} className={cx(css.ratio, css.portrait)}>
            {(cn) => (
              <ImageWithPlaceHolder
                className={cn}
                objectFit="cover"
                layout="fill"
                priority={true}
                {...backgroundImage}
                alt={backgroundImage?.alt ?? DEFAULT_ALT}
              />
            )}
          </Ratio>
        )}
      </div>
      <div className={cx(css.grid, grid)}>
        <div className={css.background} />
        <div className={css.component}>{children}</div>
      </div>
    </div>
  )
}

export default LayoutUnLogged
