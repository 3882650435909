import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ErrorOption } from 'react-hook-form'
import { SINGLE_ROUTES } from '~/lib/constants'
import { enableAccount } from '~/lib/handle-api'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useCurrency from '~/hooks/useCurrency'
import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

import getQueryPayload from '~/utils/get-query-payload'

export default function useShopifyEnableAccountMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const router = useRouter()
  const onMutationError = useOnMutationError(setError)
  const currency = useCurrency()
  const redirect = useSingleRoutesRedirect()

  return useMutation<any, unknown, any, unknown>(
    async ({
      email,
      password,
      firstName,
      lastName,
      acceptsMarketing,
      customer_birthday,
      customer_gender,
    }) => {
      const url = router?.query?.activationUrl?.toString()
      const processedUrl = window?.decodeURIComponent(url)

      const user = getQueryPayload({
        data: { email, password, firstName, lastName, acceptsMarketing },
        metafields: {
          values: {
            customer_gender,
            customer_birthday,
          },
        },
      })

      return await enableAccount({
        store: currency,
        payload: {
          user,
          url: processedUrl,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        //TODO: standardize error response
        const error = data?.errors?.[0]

        if (error) {
          const errorPayload = {
            payload: [{ ...error?.payload?.[0], code: 'MISSING_ARGUMENTS' }],
          }
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        redirect(SINGLE_ROUTES.login)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
