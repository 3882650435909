import classnames from 'classnames/bind'
import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'

import Input, { InputProps } from '~/components/Form/Input'

import css from './styles.module.scss'
import { ReactComponent as PasswordIcon } from './svg_password.svg'

const cx = classnames.bind(css)

export default function Password({
  placeholder,
  required = true,
  name = 'password',
  autoComplete = 'new-password',
  ...rest
}: InputProps) {
  const t = useTranslate()
  const [value, setValue] = useState('')
  const [processedType, setProcessedType] = useState('password')
  const passwordShown = processedType === 'text'
  const processedPlaceholder = placeholder || t('form.password')

  const onChange = (e) => {
    setValue(e.currentTarget.value)
    rest?.onChange?.(e)
  }

  return (
    <Input
      name={name}
      type={processedType}
      required={required}
      autoComplete={autoComplete}
      className={cx(css.Password)}
      placeholder={processedPlaceholder}
      onChange={onChange}
      {...rest}>
      {value?.length > 0 && (
        <PasswordIcon
          className={cx(css.seePassword, {
            [css.seePasswordActive]: passwordShown,
          })}
          onClick={() =>
            setProcessedType(processedType === 'password' ? 'text' : 'password')
          }
        />
      )}
    </Input>
  )
}
